export const BREADCRUMBS = {
  ACTIVITIES: 'Activities',
  STUDENT: 'Student',
  CALENDAR: 'Calendar',
  DASHBOARD: 'Dashboard',
  PROFILE: 'Profile',
};

export const coachArrays = [
  'coach_basic',
  'coach_intermediate',
  'coach_advanced',
];

export const MODULES = {
  BATCHES: 'BATCHES',
  BATCH_TYPES: 'BATCH_TYPES',
  CLASSES: 'CLASSES',
  SCHOOLS: 'SCHOOLS',
  STUDENTS: 'STUDENTS',
  CITIES: 'CITIES',
  BOARDS: 'BOARDS',
  CURRICULUMS: 'CURRICULUMS',
  GRADES: 'GRADES',
  TUTORS: 'TUTORS',
  TUTOR_MANAGERS: 'TUTOR_MANAGERS',
  CLASS_CONFIGURATION: 'CLASS_CONFIGURATION',
  CONTENT_CONFIGURATION: 'CONTENT_CONFIGURATION',
  SUBJECTS: 'SUBJECTS',
  SAM: 'SAM',
  CONFIGURATION: 'CONFIGURATION',
  SEGMENTS: 'SEGMENTS',
  BOOKS: 'BOOKS',
  ROLES: 'ROLES',
  SUBSCRIPTIONS: 'UPRIO_SUBSCRIPTIONS',
  CLASS_FEEDBACKS: 'CLASS_FEEDBACKS',
  CLASS_SUMMARIES: 'CLASS_SUMMARIES',
  PRACTICE_MODULE: 'PRACTICE',
  EVALUATIONS: 'EVALUATIONS',
  LECTURES: 'LECTURES',
  CHAPTERS: 'CHAPTERS',
  RECORDINGS: 'RECORDINGS',
  ASSISTANT_VIEW: 'ASSISTANT_VIEW',
};

export const EVALUATION_STATUS = {
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
  EXPIRED: 'expired',
  INPROGRESS: 'on-going',
};

export const CLASS_TYPE_OPTIONS = [
  { value: 'regular_class', label: 'Regular Class' },
  { value: 'practice_sheet', label: 'Practice Sheet' },
  { value: 'intervention_class', label: 'Intervention Class' },
  { value: 'evaluation_class', label: 'Evaluation Class' },
  { value: 'revision_class', label: 'Revision Class' },
  { value: 'demo_class', label: 'Demo Class' },
  { value: 'mock_paper', label: 'Mock Paper' },
];

export const EVALUATION_TYPE_OPTIONS = [
  { value: 'notebook_evaluation', label: 'Notebook' },
  { value: 'poll_evaluation', label: 'Poll' },
  { value: 'oral_evaluation', label: 'Oral' },
];

export const STUDENT_EVALUATION_EXPIRED = [
  { value: 'not_passed', label: 'Not Passed' },
  { value: 'passed_in_2_days', label: 'Passed in last 2 days' },
  { value: 'passed_2_to_1w', label: 'Passed (2 days - 1 week)' },
  { value: 'passed_greater_than_1w', label: 'Passed (> 1 week)' },
];

export const TUTOR_REVIEW_SUBMISSION = [
  { value: 'blank_submission', label: 'Blank Submission' },
  { value: 'partial_submission', label: 'Partial Submission' },
  {
    value: 'wrong_submission',
    label: 'Wrong Submission( Not related to the practice sheet )',
  },
  { value: 'correct_submission', label: 'Correct Submission' },
];

export const SUBMISSION_STATUS = {
  NOT_SUBMITTED: 'not_submitted',
  SUBMITTED_WITHIN_DEADLINE: 'submitted_within_deadline',
  SUBMITTED_POST_DEADLINE: 'submitted_post_deadline',
  CORRECT_SUBMISSION: 'correct_submission',
  WRONG_SUBMISSION: 'wrong_submission',
  PARTIAL_SUBMISSION: 'partial_submission',
  BLANK_SUBMISSION: 'blank_submission',
};

export const TIME_FILTER_OPTIONS = [
  { label: '7 days', value: 'passed_in_1w' },
  { label: '15 days', value: 'passed_in_15d' },
  { label: '1 month', value: 'passed_greater_than_1w' },
];

export const PRACTICE_SHEET_STATUSES = [
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive  ',
  },
];

export const CLASS_STATUS_OPTIONS = [
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Ended',
    value: 'ended',
  },
];

export const THIRTY_MINUTES = 30 * 60 * 1000;
export const CHECK_INTERVAL = 60 * 1000;

export const CONFIG_OPTION = [
  { value: 'classConfig', label: 'Class Config' },
  { value: 'lectureConfig', label: 'Lecture Config' },
];

export const PRACTICE_SHEET_STATUS = [
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
];

export const STEP_SCORES = {
  INCORRECT: 'incorrect',
  CORRECT: 'correct',
  STUDENT_COULD_NOT_SOLVE: 'student_could_not_solve',
};

export const BATCH_TYPES = {
  DEMO: 'DEMO',
  REGULAR: 'REGULAR',
  REVISION: 'REVISION',
  PRACTICE: 'PRACTICE',
  OTHER: 'OTHER',
};

export const EPIC_SEARCH_HELPER = {
  [MODULES.STUDENTS]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) => `${result['name']} (${result['id']})`,
  },
  [MODULES.SCHOOLS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.BATCHES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.BATCH_TYPES]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) =>
      result['type']
        ? `${result['name']}-${result['type']}`
        : `${result['name']}`,
  },
  [MODULES.BOARDS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.CITIES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.TUTORS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.CLASSES]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) => `${result['name']}\n${result['id']}`,
  },
  [MODULES.CLASS_CONFIGURATION]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) => `${result['name']}\n${result['referenceId']}`,
  },
  [MODULES.CURRICULUMS]: {
    displayKey: 'referenceId',
    valueKey: 'id',
  },
  [MODULES.GRADES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.SUBJECTS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.SAM]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.SEGMENTS]: {
    displayKey: 'name',
    valueKey: 'id',
    labelRender: (result) => `${result['name']}\n${result['referenceId']}`,
  },
  [MODULES.CONTENT_CONFIGURATION]: {
    displayKey: 'referenceId',
    valueKey: 'id',
  },
  [MODULES.SUBSCRIPTIONS]: {
    displayKey: 'status',
    valueKey: 'id',
  },
  [MODULES.BOOKS]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.ROLES]: {
    displayKey: 'name',
    valueKey: 'id',
  },
  [MODULES.CLASS_FEEDBACKS]: {
    displayKey: 'status',
    valueKey: 'id',
  },
  [MODULES.CLASS_SUMMARIES]: {
    displayKey: 'className',
    valueKey: 'classId',
    labelRender: (result) => `${result['batchName']} (${result['classId']})`,
  },
};

export const EPIC_SANITY_SEARCH_HELPER = {
  [MODULES.BOARDS]: {
    displayKey: 'name',
    valueKey: '_id',
  },
  [MODULES.SUBJECTS]: {
    displayKey: 'name',
    valueKey: '_id',
  },
  [MODULES.GRADES]: {
    displayKey: 'name',
    valueKey: '_id',
  },
  [MODULES.CHAPTERS]: {
    displayKey: 'name',
    valueKey: '_id',
  },
  [MODULES.LECTURES]: {
    displayKey: 'title',
    valueKey: '_id',
  },
  [MODULES.BOOKS]: {
    displayKey: 'name',
    valueKey: '_id',
    labelRender: (result) => `${result['name']} (${result?.subject?.name})`,
  },
};

export const SELECT_CUSTOM_STYLES = {
  multiValue: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: 'var(--vz-primary)',
    color: 'var(--vz-white)',
  }),
  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    color: 'var(--vz-white)',
    whiteSpace: 'pre-wrap',
  }),
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isFocused ? 'var(--vz-white)' : 'var(--vz-black)',
    backgroundColor: state.isFocused ? 'var(--vz-primary)' : 'var(--vz-white)',
    whiteSpace: 'pre-wrap',
  }),
};

export const PAYMENT_AGGREGATOR = {
  RAZORPAY: 'RAZORPAY',
  PHONEPE: 'PHONEPE',
};

export const SHOW_PLUGIN = {
  IFRAME: 'iframe',
  WHITEBOARD: 'whiteboard',
};

export const SHOW_TAB = {
  PREVIEW: 'preview',
  EVALUATION: 'evaluation',
};

export const QC_WISE_UNDERSTANDING_LEVEL_HEADERS = [
  { key: 1, name: 'Question Family' },
  { key: 2, name: 'Question Family Description' },
  { key: 3, name: 'Assessment Completed (Yes/No)' },
  { key: 4, name: 'Questions Attempted by Student' },
  { key: 5, name: '% Answered Correctly' },
  { key: 6, name: 'Student Understanding Level' },
  { key: 7, name: 'Skills to be Taught in the Intervention Class' },
  {
    key: 8,
    name: 'Does the Student Tend to Make Silly Mistakes in this Family',
  },
  { key: 9, name: 'Type of Intervention Required' },
];

export const GAP_STATUS_ORDER = [
  'qc_is_not_gap_ni',
  'qc_is_not_gap_li',
  'qc_is_gap_hi',
  'qc_is_gap_snd',
  'cant_evaluate',
  'cant_evaluate_not_enough_data',
];
export const SUBJECTS = {
  MATHS: 'Maths',
  SCIENCE: 'Science',
};

export const PAYMENT_ROLES = ['SAM_MANAGER', 'SUPER_ADMIN'];

export const BATCH_TUTOR_TYPES = { SUBJECT: 'subject', PROCTOR: 'proctor' };

export const INCLASS_NOTIFICATION_IDS = {
  NETWORK: 'network-issue',
  AUDIO: 'audio-lag',
  CPU: 'cpu-issue',
};

export const CLASS_TYPES_MAP = {
  REGULAR_CLASS: "regular_class",
  REGULAR_EVALUATION_CLASS: "regular_evaluation_class",
  INTERVENTION_CLASS: "intervention_class",
  INTERVENTION_CLASS_2: "intervention_class_2",
  INTERVENTION_EVALUATION_CLASS: "intervention_evaluation_class",
  REVISION_CLASS: "revision_class",
  REVISION_EVALUATION_CLASS: "revision_evaluation_class",
  MOCK_PAPER: "mock_paper",
  MOCK_PAPER_DISCUSSION_CLASS: "mock_paper_discussion_class",
  DEMO_CLASS: "demo_class",
  DOUBT_SOLVING_CLASS: "doubt_solving_class",
  GUIDED_PRACTICE_PLUS_ADVANCED_CLASS: "guided_practice_plus_advanced_class",
  FOUNDATIONAL_CLASS: "foundational_class",
};

export const CLASS_TYPES_COLOR_MAP = {
  [CLASS_TYPES_MAP.REGULAR_CLASS]: 'success',
  [CLASS_TYPES_MAP.REGULAR_EVALUATION_CLASS]: 'info',
  [CLASS_TYPES_MAP.INTERVENTION_CLASS]: 'primary',
  [CLASS_TYPES_MAP.INTERVENTION_EVALUATION_CLASS]: 'info',
  [CLASS_TYPES_MAP.REVISION_CLASS]: 'warning',
  [CLASS_TYPES_MAP.REVISION_EVALUATION_CLASS]: 'warning',
  [CLASS_TYPES_MAP.MOCK_PAPER]: 'info',
  [CLASS_TYPES_MAP.MOCK_PAPER_DISCUSSION_CLASS]: 'primary',
  [CLASS_TYPES_MAP.DEMO_CLASS]: 'secondary',
  [CLASS_TYPES_MAP.DOUBT_SOLVING_CLASS]: 'primary',
  [CLASS_TYPES_MAP.GUIDED_PRACTICE_PLUS_ADVANCED_CLASS]: 'success',
  [CLASS_TYPES_MAP.INTERVENTION_CLASS_2]: 'info',
  [CLASS_TYPES_MAP.FOUNDATIONAL_CLASS]: 'warning',
};

export const CLASS_TYPES_SHORT_FORMS = {
  [CLASS_TYPES_MAP.REGULAR_CLASS]: "Teach",
  [CLASS_TYPES_MAP.REGULAR_EVALUATION_CLASS]: "Eval",
  [CLASS_TYPES_MAP.INTERVENTION_CLASS]: "I1",
  [CLASS_TYPES_MAP.INTERVENTION_CLASS_2]: "I2",
  [CLASS_TYPES_MAP.INTERVENTION_EVALUATION_CLASS]: "Int Ev",
  [CLASS_TYPES_MAP.REVISION_CLASS]: "Rev",
  [CLASS_TYPES_MAP.REVISION_EVALUATION_CLASS]: "Rev Eval",
  [CLASS_TYPES_MAP.MOCK_PAPER]: "Mock",
  [CLASS_TYPES_MAP.MOCK_PAPER_DISCUSSION_CLASS]: "Mock Disc",
  [CLASS_TYPES_MAP.DEMO_CLASS]: "Demo",
  [CLASS_TYPES_MAP.DOUBT_SOLVING_CLASS]: "Doubt",
  [CLASS_TYPES_MAP.GUIDED_PRACTICE_PLUS_ADVANCED_CLASS]: "GP+A",
  [CLASS_TYPES_MAP.FOUNDATIONAL_CLASS]: "Foundational",
};
