//Included Helper File with needed methods
import {
  createGuardian,
  createStudentGuardian,
  deleteStudentGuardian,
  listOfGuardians,
  listOfStudents,
  sendPasscode,
  updateGuardian,
  updateStudentGuardian,
} from 'helpers/student';

import { appDefaultMessages } from 'common/data';
import { getHeadersPayload } from 'helpers/common';
import { errorToastify, successToastify } from 'helpers/toast';
import {
  resetAddOrUpdateStudentGuardian,
  resetErrors,
  setAddOrUpdateStudentGuardian,
  setErrors,
  setGuardiansList,
  setLoader,
  setStudentsList,
} from './reducer';

export const getGuardianListService = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await listOfGuardians(payload);
    if (status === 200) {
      dispatch(setGuardiansList(data || []));
    }
  } catch (error) {
    errorToastify(
      error?.message || appDefaultMessages?.error?.somethingWentWrong,
    );
    dispatch(setLoader(false));
  }
};

export const getStudentsListService = (payload) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { data, status } = await listOfStudents(payload);
    if (status === 200) {
      dispatch(setStudentsList(data || []));
    }
  } catch (error) {
    errorToastify(
      error?.message || appDefaultMessages?.error?.somethingWentWrong,
    );
    dispatch(setLoader(false));
  }
};

export const createStudentGuardianService =
  (data = null, accessToken = null) =>
  async (dispatch) => {
    try {
      const headersInfo = getHeadersPayload(accessToken);
      const { status } = await createStudentGuardian(data, headersInfo);
      if (status === 200) {
        dispatch(
          setAddOrUpdateStudentGuardian({
            status: true,
            message: appDefaultMessages?.success?.addedSuccessfull,
          }),
        );
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const updateStudentGuardianService =
  (studentId, data = null, accessToken = null) =>
  async (dispatch) => {
    try {
      const headersInfo = getHeadersPayload(accessToken);
      const { status } = await updateStudentGuardian(
        studentId,
        data,
        headersInfo,
      );
      if (status === 200) {
        dispatch(
          setAddOrUpdateStudentGuardian({
            status: true,
            message: appDefaultMessages?.success?.updatedSuccessfull,
          }),
        );
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const deleteStudentGuardianService =
  (data = null, accessToken = null) =>
  async (dispatch) => {
    try {
      const headersInfo = getHeadersPayload(accessToken);
      const { status } = await deleteStudentGuardian(data, headersInfo);
      if (status === 200) {
        dispatch(
          setAddOrUpdateStudentGuardian({
            status: true,
            message: appDefaultMessages?.success?.deletedSuccessfull,
          }),
        );
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const createGuardianService =
  (data = null, accessToken = null) =>
  async (dispatch) => {
    try {
      const headersInfo = getHeadersPayload(accessToken);
      const { status } = await createGuardian(data, headersInfo);
      if (status === 200) {
        dispatch(
          setAddOrUpdateStudentGuardian({
            status: true,
            message: appDefaultMessages?.success?.addedSuccessfull,
          }),
        );
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const updateGuardianService =
  (data = null, accessToken = null) =>
  async (dispatch) => {
    try {
      const headersInfo = getHeadersPayload(accessToken);
      const { status } = await updateGuardian(data, headersInfo);
      if (status === 200) {
        dispatch(
          setAddOrUpdateStudentGuardian({
            status: true,
            message: appDefaultMessages?.success?.updatedSuccessfull,
          }),
        );
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const resetAddOrUpdateStudentGuardianService =
  () => async (dispatch) => {
    dispatch(resetAddOrUpdateStudentGuardian());
  };

export const resetStudentGuardianErrorService = () => async (dispatch) => {
  dispatch(resetErrors());
};

export const sendPasscodeService = (studentId) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { status } = await sendPasscode(studentId);
    if (status === 200) {
      successToastify('Passcode successfully sent to the student');
    }
  } catch (error) {
    errorToastify(error?.message || 'Something went wrong!');
  }
  dispatch(setLoader(false));
};
