import { getClassConfiguration } from 'helpers/classes';
import { getHeadersPayload } from 'helpers/common';
import {
  getStudentNotesService,
  getTutorNudge,
  getTutorSuggestions,
  sendMeetingTranscripts,
  sendStudentClassNote,
  startDyteRecording,
  updateAcknowledgedStatus,
  updateSuggestionAcknowledgedStatus,
  updateTutorStats,
  updateTutorStatus,
  updateTutorVideoStatus,
} from 'helpers/inClass';
import {
  catchErrors,
  setClassConfiguration,
  setHuddleRooms,
  setLoader,
  setStartDyteRecording,
  setStudentNotes,
} from './reducer';

export const sendMeetingTranscriptsService = (dataTopost) => async () => {
  try {
    const { data, status } = await sendMeetingTranscripts(dataTopost);
    // console.log('---list of scheduled classes--', data);
    status === 200 && console.log('transcripts send successfully');
  } catch (error) {
    console.log('error in send meeting transcripts service', error);
  }
};

export const sendStudentClassNoteService = (dataToPost) => async (dispatch) => {
  try {
    const { status } = await sendStudentClassNote(dataToPost);
    status === 200 && dispatch(getStudentNotes(dataToPost.studentId));
  } catch (error) {
    console.log('error in send class notes service', error);
  }
};

export const getStudentNotes =
  (id = '') =>
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const { data, status, message } = await getStudentNotesService(id);
      if (status === 200) {
        dispatch(setStudentNotes(data));
      } else {
        dispatch(catchErrors({ status, message }));
      }
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const getClassConfigurationForStudent =
  (classId, accessToken) => async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const payload = getHeadersPayload(accessToken);
      const { data, status } = await getClassConfiguration(classId, payload);
      if (status === 200) {
        dispatch(setClassConfiguration(data));
      }
      dispatch(setLoader(false));
    } catch (e) {}
  };

export const startRecordingService = (classId, params) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const { status } = await startDyteRecording(classId, params);
    status === 200 && dispatch(setStartDyteRecording(status));
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message || '' };
    dispatch(catchErrors(error));
  }
};

export const setTheHuddleRooms = (data) => (dispatch) => {
  const areRoomsEmpty = Object.values(data).every((room) => room.length === 0);
  if (!areRoomsEmpty) {
    dispatch(setHuddleRooms(data));
  }
};

export const updateNudgeAcknowledgedStatus =
  (dataToPost) => async (dispatch) => {
    try {
      updateAcknowledgedStatus(dataToPost);
    } catch (err) {
      const error = { status: err?.status || 400, message: err?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const updateSuggestionsAcknowledgedStatus =
  (dataToPost) => async (dispatch) => {
    try {
      const { status } = await updateSuggestionAcknowledgedStatus(dataToPost);
      if (status === 200) {
        return status;
      }
    } catch (err) {
      const error = { status: err?.status || 400, message: err?.message || '' };
      dispatch(catchErrors(error));
    }
  };

export const getNudgeByClassId = (classId) => async (dispatch) => {
  try {
    const { data, status } = await getTutorNudge(classId);
    if (status === 200) {
      return data;
    }
    return null;
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message || '' };
    dispatch(catchErrors(error));
    return null;
  }
};

export const getTutorSuggestionsByClassId = (classId) => async (dispatch) => {
  try {
    const { data, status } = await getTutorSuggestions(classId);
    if (status === 200) {
      return data;
    }
    return null;
  } catch (e) {
    const error = { status: e?.status || 400, message: e?.message || '' };
    dispatch(catchErrors(error));
    return null;
  }
};

export const updateTutorJoinStatus =
  ({ classId, tutorId }) =>
  async (dispatch) => {
    try {
      const { data, status } = await updateTutorStatus({ classId, tutorId });
      if (status === 200) {
        return data;
      }
      return null;
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
      return null;
    }
  };

export const tutorStatsBulk =
  ({ classId, participantId, participantType, participantEvents }) =>
  async (dispatch) => {
    try {
      const { data, status } = await updateTutorStats({
        classId: Number(classId),
        participantId: Number(participantId),
        participantType,
        participantEvents,
      });
      if (status === 200) {
        return data;
      }
      return null;
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
      return null;
    }
  };

export const tutorVideoStatue =
  ({
    classId,
    participantId,
    participantType = 'TUTOR',
    mediaType,
    state,
    reason,
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await updateTutorVideoStatus({
        classId,
        participantId,
        participantType,
        mediaType,
        state,
        reason,
      });
      if (status === 200) {
        return data;
      }
      return null;
    } catch (e) {
      const error = { status: e?.status || 400, message: e?.message || '' };
      dispatch(catchErrors(error));
      return null;
    }
  };
