import { getFullName } from 'pages/DyteMeetings/Components/component/OngoingChapter';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRolesList } from 'slices/utils/reducer';
import { getUserDetails } from 'slices/admin/auth/reducer';
import { isEmpty } from 'lodash';
import { zohoChat } from '../../config';

const getMediaDevices = () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.error('Media Devices API not supported in this browser');
    return Promise.reject(new Error('Media Devices API not supported'));
  }

  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      const cameras = [];
      const microphones = [];
      const speakers = [];
      let activeCamera = '';
      let activeMicrophone = '';
      let activeSpeaker = '';

      devices.forEach((device) => {
        if (device.kind === 'videoinput') {
          cameras.push(device.label);
          if (device.deviceId === 'default') {
            activeCamera = device.label;
          }
        } else if (device.kind === 'audioinput') {
          microphones.push(device.label);
          if (device.deviceId === 'default') {
            activeMicrophone = device.label;
          }
        } else if (device.kind === 'audiooutput') {
          speakers.push(device.label);
          if (device.deviceId === 'default') {
            activeSpeaker = device.label;
          }
        }
      });

      if (isEmpty(activeCamera) && cameras.length > 0) {
        activeCamera = cameras[0] ? cameras[0] : 'No Active Camera Source';
      }
      if (isEmpty(activeMicrophone) && microphones.length > 0) {
        activeMicrophone = microphones[0]
          ? microphones[0]
          : 'No Active Mic Source';
      }
      if (isEmpty(activeSpeaker) && speakers.length > 0) {
        activeSpeaker = speakers[0] ? speakers[0] : 'No Active Speaker Source';
      }

      return {
        cameras,
        microphones,
        speakers,
        activeCamera: activeCamera,
        activeMicrophone: activeMicrophone,
        activeSpeaker: activeSpeaker,
      };
    })
    .catch((error) => {
      console.error('Error accessing media devices:', error);
      return {
        cameras: [],
        microphones: [],
        speakers: [],
        activeCamera: 'No Active Camera Source',
        activeMicrophone: 'No Active Mic Source',
        activeSpeaker: 'No Active Speaker Source',
      };
    });
};

const ZohoSalesIQ = (props) => {
  const zohoUrl = process.env.REACT_APP_ZOHO_SRC_URL;
  const userDetails = useSelector(getUserDetails);
  const getRolesListRedux = useSelector(getRolesList);
  const [landingScreen, setLandingScreen] = useState(props.landingScreen);

  const userData = useMemo(() => {
    const roleId = userDetails?.roleId;
    const userRole = getRolesListRedux?.find((role) => role?.id === roleId);
    return {
      userId: userDetails.id,
      role: userRole ? userRole.name : '',
      name: userDetails
        ? getFullName(userDetails?.firstName, userDetails?.lastName)
        : '',
      email: userDetails?.email || '',
      contactNumber: userDetails?.mobileNumber || '',
    };
  }, [userDetails, getRolesListRedux]);

  useEffect(() => {
    const { name, email, contactNumber, role, userId } = userData;

    if (isEmpty(userDetails) || !name || !email || !contactNumber || !role) {
      console.error('Required user details or role information are missing.');
      return;
    }

    const initializeZoho = async () => {
      try {
        let sources = {};
        if (landingScreen === zohoChat.inClassLandingPage) {
          sources = await getMediaDevices();
        }
        window.$zoho = window.$zoho || {};
        window.$zoho.salesiq = window.$zoho.salesiq || {};
        window.$zoho.salesiq.ready = function () {
          try {
            window.$zoho.salesiq.visitor.name(name);
            window.$zoho.salesiq.visitor.email(email);
            window.$zoho.salesiq.visitor.contactnumber(
              contactNumber.toString(),
            );

            let additionalInfo = {
              userId: userId,
              role: role,
              landingScreen: landingScreen,
            };
            if (landingScreen === zohoChat.inClassLandingPage) {
              additionalInfo = {
                ...additionalInfo,
                activeCamera: sources.activeCamera,
                activeSpeaker: sources.activeSpeaker,
                activeMicrophone: sources.activeMicrophone,
              };
            }
            window.$zoho.salesiq.visitor.info(additionalInfo);
          } catch (error) {
            console.error('Error setting Zoho visitor info:', error);
          }
        };

        const widgetScript = document.createElement('script');
        widgetScript.id = 'zsiqscript';
        widgetScript.src = process.env.REACT_APP_ZOHO_SRC_URL;
        widgetScript.defer = true;

        const style = document.createElement('style');
        style.textContent = `
            .zsiq-float { 
                right: 25px !important; 
                bottom: 50px !important;
                z-index: 10 !important; 
            }`;
        document.head.appendChild(style);
        document.body.appendChild(widgetScript);

        return () => {
          if (document.body.contains(widgetScript)) {
            document.body.removeChild(widgetScript);
          }
          delete window.$zoho;
        };
      } catch (error) {
        console.error('Error setting up Zoho SalesIQ:', error);
      }
    };

    initializeZoho();
  }, [userDetails, userData]);

  return null;
};

export default ZohoSalesIQ;
