import React, { Suspense, useEffect } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// React-Dates
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
//import Scss
import 'assets/scss/themes.scss';

//imoprt Route
import Route from 'Routes';

//import custom css
import 'assets/scss/config/material/custom.scss';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "helpers/firebase_helper";

// Fake Backend
import fakeBackend from 'helpers/AuthType/fakeBackend';
import { isTokenExpired } from 'helpers/common';
import Cookie from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProfileInformationRedux,
  getSelectedStudentInfoRedux,
} from 'slices/student-guardian/account/profile/reducer';
import { loginAdminService } from 'slices/thunks';
// import { socket } from './Components/Hooks/useSocketConnection';

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

function App() {
  const loggedInProfile = useSelector(getProfileInformationRedux);
  const selectedProfile = useSelector(getSelectedStudentInfoRedux);
  const dispatch = useDispatch();
  const token = Cookie.get(`${process.env.REACT_APP_DOMAIN_ENV}Token`);

  const loading = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner-border text-primary avatar-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    /*socket.on('connect', () => {
      console.log('server connected from app js');
    });*/
    // dispatch(initSocketService())
    // socketService.connect();
    //   alert({loggedInProfile});
    //   console.log({selectedProfile});
    //   const socket = io('http://localhost:4000', {
    //     query: {
    //       id: 'abc123', // Replace with the unique ID of the client
    //     },}); // Update the URL with your server URL
    //   // Replace 'abc123' with the actual client ID you want to connect to
    //   const clientId = 'abc123';
    //   // Emit the "hello world" signal to the specific client
    //   // socket.emit('helloWorld', clientId);
    //   // Listen for the "helloWorld" event from the server
    //   socket.on('helloWorld', (message) => {
    //     alert(`Received hello world message: ${message}`);
    //     console.log(`Received hello world message: ${message}`);
    if (token && !isTokenExpired(token)) {
      dispatch(loginAdminService());
    }
  }, []);

  //   // // Clean up the socket connection on component unmount
  //   // return () => {
  //   //   socket.disconnect();
  //   // };
  // }, []);

  return (
    <React.Fragment>
      <Suspense fallback={loading()}>
        <Route />
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}

export default App;
