import { useState, useEffect, useRef, useContext, createContext } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import {
  getProfileInformationRedux,
  getSelectedStudentInfoRedux,
} from 'slices/student-guardian/account/profile/reducer';
import { getUserDetails } from 'slices/admin/auth/reducer';
import { getJoinClassroomTokenRedux } from 'slices/student-guardian/account/dashboard/reducer';
import { base } from 'config';
import { useSearchParams, useParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

// Create a context for the socket
const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const isConnectingRef = useRef(false);
  const connectionParamsRef = useRef(null);

  const authUserInfo = useSelector(getProfileInformationRedux);
  const selectedStudentData = useSelector(getSelectedStudentInfoRedux);
  const getTeacherAuth = useSelector(getUserDetails);
  const studentClassDetails = useSelector(getJoinClassroomTokenRedux);
  const params = useParams();
  const [searchParams] = useSearchParams();

  const initializeSocket = (connectionParams) => {
    if (isConnectingRef?.current) {
      return;
    }

    if (
      socket?.connected &&
      JSON.stringify(connectionParamsRef.current) ===
        JSON.stringify(connectionParams)
    ) {
      return;
    }

    isConnectingRef.current = true;

    if (socket) {
      socket.removeAllListeners();
      socket.disconnect();
    }

    const { id, userType, classId } = connectionParams;

    const newSocket = io(process.env.REACT_APP_VIDEO_ANALYTICS_BE, {
      query: { id, userType, classId },
      transports: ['websocket'],
    });

    newSocket.on('connect', () => {
      isConnectingRef.current = false;
      connectionParamsRef.current = connectionParams;

      datadogLogs.logger.info(
        `Socket Connected - StudentID: ${id}, classId: ${classId}, userType: ${userType}`,
        { userType, classId, studentId: id },
      );
    });

    newSocket.on('connect_error', (error) => {
      isConnectingRef.current = false;
      datadogLogs.logger.error(
        `Socket Connection Error - StudentID: ${id}, classId: ${classId}, userType: ${userType}`,
        { error: error.message, userType, classId, studentId: id },
      );
    });

    newSocket.on('disconnect', (reason) => {
      isConnectingRef.current = false;
      datadogLogs.logger.info(
        `Socket Disconnected - StudentID: ${id}, classId: ${classId}, userType: ${userType}`,
        { reason, userType, classId, studentId: id },
      );
    });

    setSocket(newSocket);
  };

  useEffect(() => {
    let id = '';
    let classId = '';
    let userType = authUserInfo?.userType;
    const authToken = searchParams.get('authToken');

    if (authUserInfo?.userType === base?.USER_TYPES?.GUARDIAN) {
      userType = base?.USER_TYPES?.GUARDIAN;
      classId = params?.id;
      id = selectedStudentData?.members?.id || '';
    } else if (
      authToken ||
      authUserInfo?.userType === base?.USER_TYPES?.STUDENT
    ) {
      userType = base?.USER_TYPES?.STUDENT;
      classId = studentClassDetails?.classId;
      id = studentClassDetails?.studentId || authUserInfo?.id;
    } else {
      classId = searchParams.get('id');
      userType = 'tutor';
      id = getTeacherAuth?.id || searchParams.get('assitantId') || '';
    }

    if (id && userType && classId) {
      initializeSocket({ id, userType, classId });
    }

    return () => {
      if (socket) {
        console.debug('Cleaning up socket connection');
        socket.removeAllListeners();
        socket.disconnect();
      }
    };
  }, [
    authUserInfo?.id,
    authUserInfo?.userType,
    getTeacherAuth?.id,
    params?.id,
    searchParams,
    selectedStudentData?.members?.id,
    socket,
    studentClassDetails?.classId,
    studentClassDetails?.studentId,
  ]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
