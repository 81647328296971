const defaultStudentGuardianArray = [
  {
    path: '/dashboard',
    isActive: false,
    label: 'Dashboard',
  },
];

const defaultAdminArray = [
  {
    path: '/dashboard',
    isActive: false,
    label: 'Dashboard',
  },
];

const appBreadCrumb = {
  studentGuardian: {
    dashboard: {
      pageTitle: 'Dashboard',
      data: defaultStudentGuardianArray,
    },
    classes: {
      pageTitle: 'Classes',
      data: [
        ...defaultStudentGuardianArray,
        {
          path: '/account/classes',
          isActive: true,
          label: 'Classes',
        },
      ],
    },
    classDetails: {
      pageTitle: null,
      data: [
        ...defaultStudentGuardianArray,
        {
          path: '/account/classes',
          isActive: true,
          label: 'Classes',
        },
      ],
    },
    profileSettings: {
      pageTitle: 'Profile & Settings',
      data: [
        ...defaultStudentGuardianArray,
        {
          path: '/account/profile',
          isActive: true,
          label: 'Profile & Settings',
        },
      ],
    },
  },
  admin: {
    dashboard: {
      pageTitle: 'Dashboard',
      data: defaultAdminArray,
    },
    batches: {
      pageTitle: 'List Of Batches',
      data: [
        ...defaultAdminArray,
        {
          path: '/batches/list',
          isActive: true,
          label: 'Batches',
        },
      ],
    },
    demoBatches: {
      pageTitle: 'List Of Demo Batches',
      data: [
        ...defaultAdminArray,
        {
          path: '/demo-batch/list',
          isActive: true,
          label: 'Demo Batches',
        },
      ],
    },
    classes: {
      pageTitle: 'List Of Classes',
      data: [
        ...defaultAdminArray,
        {
          path: '/classes/list',
          isActive: true,
          label: 'Classes',
        },
      ],
    },
    sheet: {
      pageTitle: 'List of Home Assignments',
      data: [
        ...defaultAdminArray,
        {
          path: '/sheet/list',
          isActive: true,
          label: 'Home Assignments',
        },
      ],
    },

    practice_tracker: {
      pageTitle: 'Home Assignments Tracker', // Practice Tracker
      data: [
        ...defaultAdminArray,
        {
          path: '/practice-tracker/list',
          isActive: true,
          label: 'Home Assignments Tracker', // Practice Tracker
        },
      ],
    },
    practice_summary: {
      pageTitle: 'List of Home Assignments Summary',
      data: [
        ...defaultAdminArray,
        {
          path: '/summary/list',
          isActive: true,
          label: 'Home Assignments Summary',
        },
      ],
    },
    evaluations: {
      pageTitle: 'List Of Evaluation',
      data: [
        ...defaultAdminArray,
        {
          path: '/evaluations',
          isActive: true,
          label: 'Evaluations',
        },
      ],
    },
    subscription: {
      pageTitle: 'List Of Subscriptions',
      data: [
        ...defaultAdminArray,
        {
          path: '/subscriptions/list',
          isActive: true,
          label: 'Subscriptions',
        },
      ],
    },
    configuarations: {
      pageTitle: 'Demo Class Timings',
      data: [
        ...defaultAdminArray,
        {
          path: '/demo-class-timings/list',
          isActive: true,
          label: 'Demo Class Timings',
        },
      ],
    },
    classConfig: {
      pageTitle: 'Class Configurations',
      data: [
        ...defaultAdminArray,
        {
          path: '/class-config',
          isActive: true,
          label: 'Class Configurations',
        },
      ],
    },
    contentConfig: {
      pageTitle: 'Content Configurations',
      data: [
        ...defaultAdminArray,
        {
          path: '/content-config',
          isActive: true,
          label: 'Content Configurations',
        },
      ],
    },
    demoClasses: {
      pageTitle: 'List Of Demo Class Configurations',
      data: [
        ...defaultAdminArray,
        {
          path: '/demo-class-configurations/list',
          isActive: true,
          label: 'Demo Class Configurations',
        },
      ],
    },
    createSubTopic: {
      pageTitle: 'Create SubTopic',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/subtopics/create',
          isActive: true,
          label: 'Create SubTopic',
        },
      ],
    },
    subtopics: {
      pageTitle: 'List of SubTopics',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/subtopics/list',
          isActive: true,
          label: 'SubTopics',
        },
      ],
    },
    createTopic: {
      pageTitle: 'Create Topic',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/topics/craete',
          isActive: true,
          label: 'Create Topic',
        },
      ],
    },
    topics: {
      pageTitle: 'List of Topics',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/topics/list',
          isActive: true,
          label: 'Topics',
        },
      ],
    },
    createChapter: {
      pageTitle: 'Create Chapter',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/chapters/create',
          isActive: true,
          label: 'Create Chapter',
        },
      ],
    },
    chapters: {
      pageTitle: 'List of Chapters',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/chapters/list',
          isActive: true,
          label: 'Chapters',
        },
      ],
    },
    createBook: {
      pageTitle: 'Create Book',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/books/create',
          isActive: true,
          label: 'Create Book',
        },
      ],
    },
    books: {
      pageTitle: 'List of Books',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/books/list',
          isActive: true,
          label: 'Books',
        },
      ],
    },
    curriculums: {
      pageTitle: 'List of School Curriculums',
      data: [
        ...defaultAdminArray,
        {
          path: '/master-curriculum/curriculums/',
          isActive: true,
          label: 'School Curriculums',
        },
      ],
    },
    schoolCurriculumGroups: {
      pageTitle: 'List of School Curriculum groups',
      data: [
        ...defaultAdminArray,
        {
          path: '/school-group-curriculum/list',
          isActive: true,
          label: 'School Curriculum groups',
        },
      ],
    },
    createSchool: {
      pageTitle: 'Create School',
      data: [
        ...defaultAdminArray,
        {
          path: '/school/create',
          isActive: true,
          label: 'Create School',
        },
      ],
    },
    schools: {
      pageTitle: 'List Of Schools',
      data: [
        ...defaultAdminArray,
        {
          path: '/school/list',
          isActive: true,
          label: 'Schools',
        },
      ],
    },
    activities: {
      pageTitle: 'Activities',
      data: [
        ...defaultAdminArray,
        {
          path: '/activities',
          isActive: true,
          label: 'Activities',
        },
      ],
    },
    calendar: {
      pageTitle: 'Calendar',
      data: [
        ...defaultAdminArray,
        {
          path: '/calendar',
          isActive: true,
          label: 'Calendar',
        },
      ],
    },
    users: {
      pageTitle: 'List Of Users',
      data: [
        ...defaultAdminArray,
        {
          path: '/user/list',
          isActive: true,
          label: 'Users',
        },
      ],
    },
    guardians: {
      pageTitle: 'List Of Parents',
      data: [
        ...defaultAdminArray,
        {
          path: '/guardian',
          isActive: true,
          label: 'Parents',
        },
      ],
    },
    students: {
      pageTitle: 'List Of Students',
      data: [
        ...defaultAdminArray,
        {
          path: '/students',
          isActive: true,
          label: 'Students',
        },
      ],
    },
    studentsClasses: {
      pageTitle: 'Student Information',
      data: [
        ...defaultAdminArray,
        {
          path: '/student/list',
          isActive: true,
          label: 'Students',
        },
      ],
    },
  },
};

export { appBreadCrumb };
