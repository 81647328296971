const AdminPath = {
  INDEX: '/',
  DASHBOARD: '/dashboard',
  // DASHBOARDV2: '/dashboardV2',
  CREATE_BOOK: '/master-curriculum/books/create',
  LIST_OF_BOOKS: '/master-curriculum/books/list',
  CREATE_CHAPTER: '/master-curriculum/chapters/create',
  LIST_OF_CHAPTERS: '/master-curriculum/chapters/list',
  CREATE_TOPIC: '/master-curriculum/topics/create',
  LIST_OF_TOPICS: '/master-curriculum/topics/list',
  CREATE_SUBTOPICS: '/master-curriculum/subtopics/create',
  LIST_OF_SUBTOPICS: '/master-curriculum/subtopics/list',
  // CREATE_SEGMENTS: '/master-curriculum/subtopics/create',
  LIST_OF_SEGMENTS: '/master-curriculum/segments/list',
  // CREATE_ITEMS: '/master-curriculum/segments/items/create',
  LIST_OF_ITEMS: '/master-curriculum/segments/items/list',
  CREATE_SCHOOL: '/school/create',
  LIST_OF_SCHOOLS: '/school/list',
  LIST_OF_SCHOOL_GROUP_CURRICULUM: '/school-group-curriculum/list',
  LIST_OF_BATCHES: '/batches/list',
  LIST_OF_BATCH_QC_GAP: '/batches-qc-list',
  LIST_OF_CHAPTER_QC_GAP: '/chapters-qc-list',
  // LIST_OF_BATCHESV2: '/batchesV2/list',

  LIST_OF_DEMO_BATCHES: '/demo-batch/list',
  LIST_OF_CLASSES: '/classes/list',
  LIST_OF_EVALUATIONS: '/evaluations',
  LIST_OF_PRACTICE_SHEET: '/home-assignments',
  LIST_OF_PRACTICE_CLASSES: '/practice-classes/list',
  LIST_OF_PRACTICE_TRACKER: '/practice-tracker/list',

  // LIST_OF_CLASSESV2: '/classesV2/list',

  LIST_OF_DEMO_CLASS_CONFIGURATIONS: '/demo-class-configurations/list',
  LIST_OF_DEMO_CLASSES_V1: '/demo-class/list/:batchId?',
  LIST_OF_STUDENTS_CLASSES: '/student-class/list/:studentId',
  LIST_OF_USERS: '/user/list',
  // LIST_OF_STUDENTS: '/student/list',
  LIST_OF_STUDENTS: '/students/list',
  LIST_OF_FEEDBACKS: '/feedbacks/list',
  LIST_OF_STUDENT_QC_GAP: '/student-qc-gap/list',
  LIST_OF_CHAPTER_QC_GAP_REPORT: '/generate-report/list',

  LIST_OF_GUARDIANS: '/guardian/list',
  LIST_OF_DEMO_CLASS_TIMINGS: '/demo-class-timings/list',
  NOT_FOUND: '/*',
  LIST_OF_SCHOOL_CURRICULUM: '/master-curriculum/curriculums',
  ONLY_STUDENT_STREAMING: '/only-student/:classId/:studentId',
  CONTENT_CONFIG: '/content-config',
  CLASS_CONFIG: '/class-config',

  LIST_OF_SUBSCRIPTIONS: '/subscription',
  LIST_OF_PAYMENTS: '/payment',
  SANITY_ASSETS: '/sanity-assets',
  REPORT: '/report',
  LIST_OF_CLASS_SUMMARIES: '/class-summaries',
};

export default AdminPath;
