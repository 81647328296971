//Include Both Helper File with needed methods
import io from 'socket.io-client';

import { setSocket } from './reducer';

let socket;

export const initSocketService = (data) => async (dispatch) => {
  try {
    socket = io(process.env.REACT_APP_VIDEO_ANALYTICS_BE, {
      query: {
        id: 131,
      },
    });
    console.log('socket connected -- ', socket);
    // await dispatch(setSocket(socket));
    return socket;
  } catch (error) {
    console.log('error in init socket ', error);
  }
};

export const getSocketService = (data) => async (dispatch) => {
  return socket;
};
